// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../../api/Api.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ProjectProvider from "../../../../../models/ProjectProvider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProviderStatusButtonScss from "./ProjectProviderStatusButton.scss";

var css = ProjectProviderStatusButtonScss;

function ProjectProviderStatusButton(props) {
  var setReviewStatus = props.setReviewStatus;
  var reviewStatus = props.reviewStatus;
  var projectProviderId = props.projectProviderId;
  var updateProjectProviderStatus = function (reviewStatus) {
    $$Promise.wait(Api.updateProjectProviderStatus(projectProviderId, reviewStatus), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "ProjectProviderStatusButton",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectProviderStatusButton.make"
                        }, "UpdateStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
            var res = x._0;
            setReviewStatus(function (param) {
                  return ProjectProvider.Status.fromString(res.projectProvider.reviewStatus);
                });
          }));
  };
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Teal",
                            className: css.ctaButton,
                            children: "Update Status",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "SM",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsx(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "LeftEdge"
                      },
                      className: css.popoverBody,
                      children: reviewStatus === "RegistrationRequested" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Invited",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "SM",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      onClick: (function (param) {
                                          updateProjectProviderStatus("Invited");
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Uninvited",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "SM",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      onClick: (function (param) {
                                          updateProjectProviderStatus("Uninvited");
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Registered",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "SM",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      onClick: (function (param) {
                                          updateProjectProviderStatus("Registered");
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Registration Denied",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "SM",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      onClick: (function (param) {
                                          updateProjectProviderStatus("RegistrationDenied");
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Unresponsive",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "SM",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      onClick: (function (param) {
                                          updateProjectProviderStatus("Unresponsive");
                                        })
                                    })
                              ]
                            }) : (
                          reviewStatus === "RegistrationDenied" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Invited",
                                            JsxRuntime.jsx(IconSelect.make, {
                                                  size: "SM",
                                                  color: "LightestGray",
                                                  className: css.popoverBodyLinkIcon
                                                })
                                          ],
                                          className: css.popoverBodyLink,
                                          onClick: (function (param) {
                                              updateProjectProviderStatus("Invited");
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Uninvited",
                                            JsxRuntime.jsx(IconSelect.make, {
                                                  size: "SM",
                                                  color: "LightestGray",
                                                  className: css.popoverBodyLinkIcon
                                                })
                                          ],
                                          className: css.popoverBodyLink,
                                          onClick: (function (param) {
                                              updateProjectProviderStatus("Uninvited");
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Registration Requested",
                                            JsxRuntime.jsx(IconSelect.make, {
                                                  size: "SM",
                                                  color: "LightestGray",
                                                  className: css.popoverBodyLinkIcon
                                                })
                                          ],
                                          className: css.popoverBodyLink,
                                          onClick: (function (param) {
                                              updateProjectProviderStatus("RegistrationRequested");
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Registered",
                                            JsxRuntime.jsx(IconSelect.make, {
                                                  size: "SM",
                                                  color: "LightestGray",
                                                  className: css.popoverBodyLinkIcon
                                                })
                                          ],
                                          className: css.popoverBodyLink,
                                          onClick: (function (param) {
                                              updateProjectProviderStatus("Registered");
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Unresponsive",
                                            JsxRuntime.jsx(IconSelect.make, {
                                                  size: "SM",
                                                  color: "LightestGray",
                                                  className: css.popoverBodyLinkIcon
                                                })
                                          ],
                                          className: css.popoverBodyLink,
                                          onClick: (function (param) {
                                              updateProjectProviderStatus("Unresponsive");
                                            })
                                        })
                                  ]
                                }) : (
                              reviewStatus === "Registered" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Invited",
                                                JsxRuntime.jsx(IconSelect.make, {
                                                      size: "SM",
                                                      color: "LightestGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              onClick: (function (param) {
                                                  updateProjectProviderStatus("Invited");
                                                })
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Uninvited",
                                                JsxRuntime.jsx(IconSelect.make, {
                                                      size: "SM",
                                                      color: "LightestGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              onClick: (function (param) {
                                                  updateProjectProviderStatus("Uninvited");
                                                })
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Registration Requested",
                                                JsxRuntime.jsx(IconSelect.make, {
                                                      size: "SM",
                                                      color: "LightestGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              onClick: (function (param) {
                                                  updateProjectProviderStatus("RegistrationRequested");
                                                })
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Registration Denied",
                                                JsxRuntime.jsx(IconSelect.make, {
                                                      size: "SM",
                                                      color: "LightestGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              onClick: (function (param) {
                                                  updateProjectProviderStatus("RegistrationDenied");
                                                })
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                "Unresponsive",
                                                JsxRuntime.jsx(IconSelect.make, {
                                                      size: "SM",
                                                      color: "LightestGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              onClick: (function (param) {
                                                  updateProjectProviderStatus("Unresponsive");
                                                })
                                            })
                                      ]
                                    }) : (
                                  reviewStatus === "Unresponsive" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Invited",
                                                    JsxRuntime.jsx(IconSelect.make, {
                                                          size: "SM",
                                                          color: "LightestGray",
                                                          className: css.popoverBodyLinkIcon
                                                        })
                                                  ],
                                                  className: css.popoverBodyLink,
                                                  onClick: (function (param) {
                                                      updateProjectProviderStatus("Invited");
                                                    })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Uninvited",
                                                    JsxRuntime.jsx(IconSelect.make, {
                                                          size: "SM",
                                                          color: "LightestGray",
                                                          className: css.popoverBodyLinkIcon
                                                        })
                                                  ],
                                                  className: css.popoverBodyLink,
                                                  onClick: (function (param) {
                                                      updateProjectProviderStatus("Uninvited");
                                                    })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Registration Requested",
                                                    JsxRuntime.jsx(IconSelect.make, {
                                                          size: "SM",
                                                          color: "LightestGray",
                                                          className: css.popoverBodyLinkIcon
                                                        })
                                                  ],
                                                  className: css.popoverBodyLink,
                                                  onClick: (function (param) {
                                                      updateProjectProviderStatus("RegistrationRequested");
                                                    })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Registered",
                                                    JsxRuntime.jsx(IconSelect.make, {
                                                          size: "SM",
                                                          color: "LightestGray",
                                                          className: css.popoverBodyLinkIcon
                                                        })
                                                  ],
                                                  className: css.popoverBodyLink,
                                                  onClick: (function (param) {
                                                      updateProjectProviderStatus("Registered");
                                                    })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Registration Denied",
                                                    JsxRuntime.jsx(IconSelect.make, {
                                                          size: "SM",
                                                          color: "LightestGray",
                                                          className: css.popoverBodyLinkIcon
                                                        })
                                                  ],
                                                  className: css.popoverBodyLink,
                                                  onClick: (function (param) {
                                                      updateProjectProviderStatus("RegistrationDenied");
                                                    })
                                                })
                                          ]
                                        }) : (
                                      reviewStatus === "Invited" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Uninvited",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      href: "#",
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Uninvited");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registration Requested",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      href: "#",
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("RegistrationRequested");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registered",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Registered");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registration Denied",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("RegistrationDenied");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Unresponsive",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Unresponsive");
                                                        })
                                                    })
                                              ]
                                            }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Invited",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Invited");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registration Requested",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("RegistrationRequested");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registered",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Registered");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Registration Denied",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("RegistrationDenied");
                                                        })
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        "Unresponsive",
                                                        JsxRuntime.jsx(IconSelect.make, {
                                                              size: "SM",
                                                              color: "LightestGray",
                                                              className: css.popoverBodyLinkIcon
                                                            })
                                                      ],
                                                      className: css.popoverBodyLink,
                                                      onClick: (function (param) {
                                                          updateProjectProviderStatus("Unresponsive");
                                                        })
                                                    })
                                              ]
                                            })
                                    )
                                )
                            )
                        )
                    })
              ]
            });
}

var make = ProjectProviderStatusButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
