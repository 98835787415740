// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ProjectProvider from "../../../../../models/ProjectProvider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProviderPanelScss from "./ProjectProviderPanel.scss";

var css = ProjectProviderPanelScss;

function ProjectProviderPanelHeading(props) {
  var reviewStatus = props.reviewStatus;
  var providerName = props.providerName;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("img", {
                              className: css.providerImage,
                              alt: providerName,
                              src: props.providerImage
                            }),
                        JsxRuntime.jsx("h5", {
                              children: providerName,
                              className: css.providerName
                            })
                      ],
                      className: css.providerPanelImageAndNameWrapper
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("button", {
                            children: ProjectProvider.Status.toString(reviewStatus),
                            className: Cx.cx([
                                  css.badge,
                                  reviewStatus === "RegistrationRequested" ? css.badgeRegRequested : (
                                      reviewStatus === "RegistrationDenied" ? css.badgeDenied : (
                                          reviewStatus === "Registered" ? css.badgeRegistered : (
                                              reviewStatus === "Unresponsive" ? css.badgeUnresponsive : (
                                                  reviewStatus === "Invited" ? css.badgeInvited : css.badgeUninvited
                                                )
                                            )
                                        )
                                    )
                                ]),
                            disabled: true
                          }),
                      className: css.badgeWrapper
                    })
              ],
              className: css.providerPanelHeadingContainer
            });
}

var make = ProjectProviderPanelHeading;

export {
  css ,
  make ,
}
/* css Not a pure module */
