// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as TopNavbar from "../../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProjectHeader from "../_components/ProjectHeader/ProjectHeader.res.js";
import * as ProjectNavbar from "../_components/ProjectNavbar/ProjectNavbar.res.js";
import * as AdminTopNavbar from "../../../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as ProjectStickyBar from "../_components/ProjectStickyBar/ProjectStickyBar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConciergeTopNavbar from "../../../dashboard/common/concierge-top-navbar/ConciergeTopNavbar.res.js";
import * as ProjectProviderPanel from "./components/ProjectProviderPanel.res.js";
import * as UserProjectProvidersScss from "./UserProjectProviders.scss";

var css = UserProjectProvidersScss;

function UserProjectProviders(props) {
  var projectStickyBarData = props.projectStickyBarData;
  var userViewContext = props.userViewContext;
  var projectId = props.projectId;
  var stickyBarUserRole = Project.Role.toRole(projectStickyBarData.userProjectRole);
  var tmp;
  switch (userViewContext) {
    case "Personal" :
        tmp = JsxRuntime.jsx(TopNavbar.make, {
              selectedCategory: "Projects"
            });
        break;
    case "Admin" :
        var exit = 0;
        switch (props.role) {
          case "Admin" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: "Projects",
                    isAdmin: true,
                    isConcierge: projectStickyBarData.isConcierge,
                    isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                  });
              break;
          case "Provider" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: "Projects",
                    isAdmin: false,
                    isConcierge: projectStickyBarData.isConcierge,
                    isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                  });
              break;
          case "User" :
          case "Visitor" :
              tmp = null;
              break;
          case "Concierge" :
          case "Agent" :
              exit = 1;
              break;
          
        }
        if (exit === 1) {
          tmp = projectStickyBarData.isConcierge ? JsxRuntime.jsx(ConciergeTopNavbar.make, {
                  selectedCategory: "Projects"
                }) : null;
        }
        break;
    case "Visitor" :
        tmp = null;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ProjectHeader.make, {
                                    title: props.projectTitle,
                                    businessName: props.projectBusinessName,
                                    userRole: stickyBarUserRole,
                                    projectId: projectId,
                                    viewContext: userViewContext,
                                    projectStatus: Project.Status.fromString(props.projectStatus)
                                  }),
                              JsxRuntime.jsx(ProjectNavbar.make, {
                                    activeTab: "Providers",
                                    userRole: stickyBarUserRole,
                                    projectId: projectId,
                                    totalProposals: props.totalProposals,
                                    viewContext: userViewContext
                                  })
                            ],
                            className: css.contentWrapper
                          }),
                      className: css.barHeading
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Project Providers",
                                      className: css.providersHeading
                                    }),
                                JsxRuntime.jsxs("p", {
                                      children: [
                                        "The following providers have been invited to this project. ",
                                        "Please use this page to manage the status of the providers. ",
                                        "Only providers with a status of \"Registered\" can access the project."
                                      ],
                                      className: css.helpText
                                    })
                              ]
                            }),
                        Belt_Array.mapWithIndex(props.projectProviders, (function (index, provider) {
                                return JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(ProjectProviderPanel.make, {
                                                  provider: provider
                                                }),
                                            className: css.providerPanelContainer
                                          }, String(index));
                              }))
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectStickyBar.make, {
                      userRole: stickyBarUserRole,
                      projectStickyBarData: projectStickyBarData,
                      canSendProposals: props.canSendProposals,
                      viewContext: userViewContext,
                      desktop: props.desktop,
                      tablet: props.tablet,
                      mobile: props.mobile
                    })
              ],
              className: css.container
            });
}

var make = UserProjectProviders;

export {
  css ,
  make ,
}
/* css Not a pure module */
