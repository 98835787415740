// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as ProjectProvider from "../../../../models/ProjectProvider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";
import * as UserProjectProviders from "./UserProjectProviders.res.js";

function UserProjectProviders__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var viewContext = props.viewContext;
  var projectStickyBar = props.projectStickyBar;
  var projectProviders = props.projectProviders;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectProviders$1 = React.useMemo((function () {
          return Belt_Array.map(projectProviders, ProjectProvider.fromJs);
        }), [projectProviders]);
  var userViewContext = React.useMemo((function () {
          return ProjectsViewContext.fromString(viewContext);
        }), [viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(projectStickyBar);
        }), [projectStickyBar]);
  return JsxRuntime.jsx(UserProjectProviders.make, {
              projectProviders: projectProviders$1,
              projectId: props.projectId,
              projectTitle: props.projectTitle,
              projectBusinessName: props.projectBusinessName,
              projectStatus: props.projectStatus,
              canSendProposals: props.canSendProposals,
              role: railsContext$1.userRole,
              userViewContext: userViewContext,
              projectStickyBarData: projectStickyBarData,
              totalProposals: props.totalProposals,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile
            });
}

var $$default = UserProjectProviders__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
