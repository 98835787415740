// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ProjectProvider from "../../../../../models/ProjectProvider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectProviderPanelBody from "./ProjectProviderPanelBody.res.js";
import * as ProjectProviderPanelScss from "./ProjectProviderPanel.scss";
import * as ProjectProviderPanelHeading from "./ProjectProviderPanelHeading.res.js";

var css = ProjectProviderPanelScss;

function ProjectProviderPanel(props) {
  var provider = props.provider;
  var match = React.useState(function () {
        return ProjectProvider.Status.fromString(provider.reviewStatus);
      });
  var reviewStatus = match[0];
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.providerPanel,
              bodyClassName: css.providerPanelBody,
              heading: JsxRuntime.jsx(ProjectProviderPanelHeading.make, {
                    providerName: provider.providerName,
                    providerImage: provider.providerImage,
                    reviewStatus: reviewStatus
                  }),
              children: JsxRuntime.jsx(ProjectProviderPanelBody.make, {
                    projectProvider: provider,
                    reviewStatus: reviewStatus,
                    setReviewStatus: match[1]
                  })
            });
}

var make = ProjectProviderPanel;

export {
  css ,
  make ,
}
/* css Not a pure module */
